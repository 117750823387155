<div class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen">
  <form [formGroup]="this.form" class="ea-form">
    <fieldset>
      <div class="flex flex-col gap-4">
        <legend>
          <h1 class="text-2xl font-bold font-title mb-4">Recover Password</h1>

          <p class="text-base">
            Please enter the email you registered with below. You will be sent
            an email with a confirmation link.
          </p>
        </legend>

        <div
          class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
        >
          <div class="ea-form-field">
            <label for="email-input" class="ea-label required"
              >Email address</label
            >
            <input
              id="email-input"
              formControlName="email"
              name="email"
              class="ea-input"
              type="email"
              placeholder="Email address"
              autocomplete="email"
              alt="email input"
            />
          </div>

          <input
            type="submit"
            class="ea-button ea-button-primary w-full"
            value="Submit"
            title="Submit"
            alt="submit"
            (click)="submit()"
            [disabled]="form.invalid || (isLoading$ | async) === true"
          />
        </div>
      </div>
    </fieldset>
  </form>
</div>
