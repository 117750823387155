import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthFacade } from '@ea/auth';
import { Observable } from 'rxjs';


@Component({
  selector: 'ea-password-recovery-page',
  templateUrl: './password-recovery-page.component.html',
  styleUrls: ['./password-recovery-page.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRecoveryPageComponent implements OnInit {
  isLoading$?: Observable<boolean>;
  form: FormGroup;

  constructor(private builder: FormBuilder, private auth: AuthFacade) {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    // this.isLoading$ = this.auth.isLoading$;
  }

  submit() {
    const payload = this.form.value;
    this.auth.recover(payload);
  }
}
